import React from "react";
import { Box, Typography, Button, Container, Grid } from "@mui/material";

const HeroSection = () => (
  <Box
    sx={{
      backgroundColor: "#f5f5f5",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "black",
    }}
  >
    <Container maxWidth="xl">
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box sx={{ textAlign: "left" }}>
            <Typography variant="h3" component="h1" gutterBottom>
              Crece con automatización
            </Typography>
            <Typography variant="h6" component="p" gutterBottom>
              Automatiza la comunicación con tus clientes y optimiza tu flujo de
              trabajo para aumentar la eficiencia y las ventas. Con PatitoChat,
              puedes gestionar todas tus conversaciones desde una única
              plataforma, ahorrando tiempo y mejorando la experiencia del
              cliente.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              sx={{ backgroundColor: "#007BFF", color: "white", mt: 2 }}
            >
              Comienza Ahora
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box
            sx={{
              textAlign: "center",
              border: "5px solid #007BFF",
              padding: 2,
            }}
          >
            <img alt="CRM" style={{ maxWidth: "100%", height: "auto" }} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  </Box>
);

export default HeroSection;
