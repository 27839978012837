import React from "react";
import { Container, Typography, Box } from "@mui/material";

const PrivacyPolicy = () => (
  <Box sx={{ backgroundColor: "#ffffff", py: 5 }}>
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" gutterBottom>
        Política de Privacidad
      </Typography>
      <Typography variant="body1" gutterBottom>
        El presente documento determina y explica como PatitoChat (“PatitoChat”,
        “Nosotros” o “Nuestra Compañía”) recolecta, procesa, almacena, comparte
        y/o protege cualquier Información Personal (tal y como se define más
        adelante) y/o cualquier otra información respecto cuando usted utiliza
        PatitoChat o los Servicios (conforme se define más abajo) (conjuntamente
        referida como la “Información”), de acuerdo con lo establecido en la Ley
        25.326 de protección de datos personales, su decreto reglamentario,
        normas complementarias y disposiciones emanadas de la Dirección Nacional
        de Protección de Datos Personales. Nosotros no utilizaremos la
        Información Confidencial con terceras partes excepto de acuerdo a lo
        previstos en las presente Política de Privacidad. Mediante el acceso a
        nuestra Plataforma o la utilización de los servicios usted acepta la
        presente Política de Privacidad.
      </Typography>
      <Typography variant="body1" gutterBottom>
        NUESTRA COMPAÑÍA CONSIDERA PRIORITARIA LA PROTECCIÓN DE LA PRIVACIDAD DE
        NUESTROS USUARIOS Y LA SEGURIDAD DE SU INFORMACIÓN.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Mediante el acceso y/o la utilización de la plataforma online de
        PatitoChat, sus aplicaciones o el sitio web patitochat.com y/o la
        contratación de nuestros servicios (los “Servicios”) Usted consiente la
        recolección, almacenamiento, divulgación y otros usos de su Información,
        de conformidad a lo establecido en la presente Política de Privacidad.
        Tal y como se ha establecido en los términos y condiciones relativos a
        los Servicios (los “TyC”), usted deberá como mínimo haber alcanzado la
        mayoría de edad para poder acceder y/o utilizar los Servicios.
      </Typography>
      <Typography variant="body1" gutterBottom>
        SE SOLICITA POR FAVOR LEER DETENIDAMENTE LA PRESENTE POLÍTICA DE
        PRIVACIDAD ANTES DE REMITIR O INCORPORAR CUALQUIER INFORMACIÓN AL SITIO
        WEB.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        1. LA INFORMACIÓN QUE RECOLECTAMOS.
      </Typography>
      <Typography variant="body1" gutterBottom>
        1.1 Información Personal. Los Servicios utilizan la información que se
        recolecta para operar, mantener y proveer la funcionalidad misma de los
        Servicios, analizando cómo dichos servicios son utilizados, conduciendo
        servicios de diagnósticos o chequeos de problemas técnicos, manteniendo
        la seguridad, contenido personal, recordatorio de información personal
        para el acceso a su cuenta, monitoreando métricas como el total de
        visitantes, tráfico, patrones demográficos y rastreando el contenido y
        usuarios de acuerdo a la legislación aplicable. En tal sentido, nosotros
        recolectamos información con el objeto de identificar a los usuarios
        (“Información Personal”). Dicha Información Personal puede incluir
        dirección de correo electrónico, nombre, apellido, dirección y número de
        teléfono, y cualquier otra información personal que el usuario haya
        proporcionado a Nuestra Compañía mediante la utilización de los
        Servicios de PatitoChat.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Nosotros recopilamos Información Personal en línea cuando, entre otros
        casos:
      </Typography>
      <Typography variant="body1" component="div">
        <ul>
          <li>
            Usted se registra para hacer uso de alguno de los servicios
            disponibles del Sitio Web.
          </li>
          <li>Usted utiliza el Sitio Web.</li>
          <li>
            Usted nos envía preguntas, consultas o comentarios o se contacta con
            el Sitio Web.
          </li>
          <li>Usted solicita información o materiales.</li>
          <li>
            Usted provee de cualquier forma información al Sitio Web (chats,
            foros, uploads, etc.).
          </li>
        </ul>
      </Typography>
      <Typography variant="body1" gutterBottom>
        Su nombre, correo electrónico y toda otra información que usted elija
        entregar, podrá ser vista por otros usuarios todo ello de acuerdo con
        sus ajustes en los Servicios.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Tal como se menciona más abajo, nosotros podremos utilizar su
        Información Personal para enviarle mensajes de marketing. En caso de que
        Usted no desee recibir dichos mensajes, puede optar por no recibirlos
        siguiendo las instrucciones previstas en dicho mensaje. En caso de que
        Usted se dirija a nosotros por medio de correo electrónico, podremos
        guardar el contenido de su correo electrónico, su dirección de correo
        electrónico y sus respuestas.
      </Typography>
      <Typography variant="body1" gutterBottom>
        1.2 Su Contenido y Comunicación. Nuestra Compañía recolecta y almacena
        todos los comentarios, comunicaciones y conversaciones que el Usuario
        realiza a través de los Servicios.
      </Typography>
      <Typography variant="body1" gutterBottom>
        1.3 Cookies. PatitoChat puede utilizar cookies para rastrear su acceso a
        los Servicios. Dicha tecnología opera ya sea colocando un pequeño
        archivo que almacena cierta información en su computadora o teléfono
        móvil, y/o accediendo a determinada información de su dispositivo.
        PatitoChat utiliza cookies y tecnología similar para reconocer su
        dispositivo y para recolectar y almacenar datos de acceso con el objeto
        de que usted no tenga que iniciar sesión cada vez que accede a los
        Servicios, para permitir a PatitoChat proveer al usuario mayores
        servicios personalizados y para recolectar cualquier otra información
        relacionada con el uso de los Servicios. Si Usted no desea que se
        instale en su disco duro una cookie puede configurar el navegador de su
        ordenador para no recibirlas. Las “cookies” se asocian únicamente con un
        usuario anónimo y su ordenador y no proporcionan por sí solas los datos
        personales del usuario, ni pueden leer datos de su disco duro, ni leer
        los archivos cookie creados por otros proveedores.
      </Typography>
      <Typography variant="body1" gutterBottom>
        1.4 Conocimiento. A través del conocimiento que postea mediante la
        utilización de los Servicios, también nos proporciona su información. Su
        conocimiento y datos sobre su conocimiento pueden ser vistos por otros
        usuarios de acuerdo con su configuración de los Servicios. PatitoChat
        puede, pero no tiene la obligación de controlar los conocimientos que
        publica en los Servicios. Podemos eliminar cualquier información que
        publique por algún motivo o sin motivo. A menos que se pueda ver el
        conocimiento de acuerdo con su configuración en los Servicios, los
        empleados de PatitoChat y PatitoChat no verán su conocimiento, excepto:
        (i) para mantener, proporcionar o mejorar los Servicios; (ii) para
        ayudarlo a resolver sus solicitudes de soporte; o (iii) como PatitoChat
        considere conveniente, en su opinión exclusiva, que es necesario para
        cumplir o evitar la violación de la ley o regulación aplicable o para
        cooperar con la aplicación de la ley.
      </Typography>
      <Typography variant="body1" gutterBottom>
        1.5 Información de ubicación geográfica. Cuando utiliza los Servicios
        por o a través de un dispositivo móvil, podemos acceder, recopilar,
        supervisar y / o almacenar remotamente “datos de ubicación”, que pueden
        incluir coordenadas de GPS (por ejemplo, latitud y/o longitud) o
        información similar sobre la ubicación de su dispositivo móvil. Los
        datos de ubicación pueden transmitir información acerca de cómo utiliza
        los Servicios. Los datos de ubicación no recopilan ni comparten ninguna
        información de identificación personal sobre usted. Los datos de
        ubicación se pueden usar junto con información de identificación
        personal. Es posible que algunas características de los Servicios,
        especialmente los servicios basados ​​en la ubicación, no funcionen
        correctamente si el uso o la disponibilidad de los datos de ubicación se
        ven afectados o deshabilitados.
      </Typography>
      <Typography variant="body1" gutterBottom>
        1.6 Identificadores de dispositivo. Cuando usted accede a los Servicios
        por o a través de un dispositivo móvil, podemos acceder, recopilar,
        supervisar y / o almacenar remotamente uno o más "identificadores de
        dispositivo". Los identificadores de dispositivo son pequeños archivos
        de datos o estructuras de datos similares almacenados o asociados a su
        dispositivo móvil, que identifican de forma única su dispositivo móvil y
        se utiliza para mejorar los Servicios. Un identificador de dispositivo
        puede ser información almacenada en conexión con el hardware del
        dispositivo, datos almacenados en conexión con el sistema operativo del
        dispositivo u otro software, o datos enviados al dispositivo por
        Nosotros. Un identificador de dispositivo puede transmitir información
        sobre cómo usted usa los Servicios. Un identificador de dispositivo no
        recopila ni comparte ninguna información de identificación personal
        sobre usted. Un identificador de dispositivo puede usarse junto con
        información de identificación personal. Un identificador de dispositivo
        puede permanecer persistentemente en su dispositivo, para ayudarlo a
        iniciar sesión más rápido y mejorar su navegación a través de los
        Servicios. Es posible que algunas características de los Servicios no
        funcionen correctamente si el uso o la disponibilidad de los
        identificadores del dispositivo se ven afectados o deshabilitados.
        PatitoChat puede acceder, recopilar y/o almacenar identificadores de
        dispositivos al habilitar los Servicios de PatitoChat.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        2. USO Y DIVULGACIÓN DE INFORMACIÓN POR PARTE DE PATITOCHAT.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.1 Almacenamiento de datos. En virtud de lo previsto por los arts. 3 y
        6 de la ley 25.326, nuestra Compañía cumple adecuadamente con la
        inscripción de la base de datos de sus usuarios ante la Dirección
        Nacional de Protección de Datos Personales y realiza las renovaciones
        anuales correspondientes, de acuerdo con la normativa vigente. Nuestra
        Compañía no recolecta Información con otra finalidad distinta o
        incompatible con la finalidad expuesta en esta Política de Privacidad ni
        que directa o indirectamente, revele datos sensibles. Dicha Información
        será almacenada facilitando, en todo momento, el ejercicio de los
        derechos de sus titulares y actualizada en el caso de ser necesario.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.2 Uso de información. El usuario reconoce y acepta que PatitoChat y/o
        los proveedores de servicios en su nombre, pueden utilizar la
        información que recolectan, incluyendo la Información Personal, con la
        finalidad de cumplir con tareas de procesamiento de datos u operaciones,
        asistencia o mejora de la experiencia del usuario y/o con el fin de
        suministrar datos de contacto a los clientes que contratan nuestros
        Servicios. Toda la información que se obtenga por este medio será
        tratada como confidencial. Nuestra Compañía no trata datos personales de
        sus usuarios para efectuar publicidad de terceros (personas físicas o
        jurídicas).
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.3 Otras transferencias. Nuestra Compañía no comparte ni comercializa
        bajo ninguna forma la información de sus usuarios, salvo consentimiento
        expreso y por escrito del usuario para hacerlo, o de acuerdo a lo
        indicado en los párrafos siguientes. Sin perjuicio de ello, el usuario
        consiente en forma expresa que PatitoChat podrá transferir total o
        parcialmente la Información a cualquiera de las sociedades controladas,
        controlantes y/o vinculadas, garantizando en todos los casos una
        adecuada confidencialidad de la Información transferida.
      </Typography>
      <Typography variant="body1" gutterBottom>
        A los efectos de proveer sus servicios, PatitoChat podrá divulgar
        Información a terceros con los que mantenga una relación de tipo laboral
        o de servicios, a los fines de la realización o prestación de servicios
        administrativos u otros servicios empresariales, como el hosting. Dichos
        terceros tendrán acceso a la Información de los usuarios necesaria para
        realizar sus funciones, pero no podrán utilizarla para otros fines.
        Además, deberán tratar la Información de conformidad con esta Política
        de Privacidad y la legislación aplicable en materia de protección de
        datos personales, respetando en todo momento la confidencialidad de los
        mismos.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Para el caso en que Nuestra Compañía o la mayor parte de sus activos
        sean adquiridos en el futuro por un tercero, la Información Personal de
        los usuarios será uno de los activos transferidos, encontrándose sujeta
        a los compromisos adquiridos en esta Política de Privacidad, salvo
        autorización expresa en contrario por parte del usuario. Igual criterio
        se aplicará en los casos de fusión, transformación o escisión societaria
        en los que intervenga Nuestra Compañía.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Asimismo, Nuestra Compañía podrá divulgar Información Personal por otros
        motivos en la medida de lo permitido y/o requerido por cualquier ley y/o
        resolución judicial.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.4. Correo electrónico.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.4.1. PatitoChat podrá enviarle correos electrónicos en relación con el
        contenido del Sitio Web, los servicios prestados por PatitoChat o sobre
        su cuenta y en respuesta a sus preguntas, pedidos, consultas o
        comentarios. PatitoChat también le podrá enviar correos electrónicos con
        información sobre productos y servicios ofrecidos por PatitoChat y/o
        terceros asociados comercialmente que le puedan resultar de interés, a
        menos que usted indique expresamente que no desea recibir dichos correos
        electrónicos a través de los procesos implementados por PatitoChat a tal
        efecto.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.4.2. Usted podrá solicitar en cualquier momento el retiro o bloqueo de
        su nombre de la base de datos a la que se refiere la presente Política
        de Privacidad siguiendo el procedimiento señalado en esta misma
        política.
      </Typography>
      <Typography variant="body1" gutterBottom>
        2.4.3. Todo correo electrónico que reciba de PatitoChat le informará
        cómo rechazar el envío de futuros correos electrónicos promocionales.
        Asimismo, usted podrá cambiar sus preferencias de recepción de correos
        electrónicos publicitarios a través de la configuración de su cuenta en
        el propio Sitio Web en cualquier momento.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        3. SEGURIDAD DE LA INFORMACIÓN PERSONAL.
      </Typography>
      <Typography variant="body1" gutterBottom>
        PatitoChat toma todas las medidas razonables y positivas para asegurar
        que la Información Personal se encuentra protegida frente a cualquier
        uso inapropiado, pérdida, acceso no autorizado, modificación y/o
        divulgación de acuerdo a lo previsto en la ley 25.326, y normas
        complementarias. PatitoChat adopta y aplica prácticas apropiadas para la
        recolección, almacenamiento y gestión de datos, y procedimientos de
        seguridad contra el acceso no autorizado, alteración, agregado,
        supresión, divulgación y/o destrucción de la Información del Usuario,
        nombre de usuario, dirección de correo electrónico, contraseña,
        información sobre transacciones y cualquier otra información almacenada
        en el Sitio. Sin embargo, ninguna información almacenada o transmitida
        mediante Internet u otra red puede ser garantizada como cien por ciento
        segura. En virtud de ello, PatitoChat no garantiza que la Información no
        pueda resultar accedida, divulgada, alterada o destruida ante la
        violación de cualquiera de las medidas de seguridad antes mencionadas.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        4. ACCESO A LA INFORMACIÓN PERSONAL.
      </Typography>
      <Typography variant="body1" gutterBottom>
        El usuario puede consultar a la Autoridad de Protección de Datos
        Personales (o la autoridad de control que en el futuro la reemplace), de
        forma gratuita, sobre la existencia de archivos, registros o bancos de
        datos, sus finalidades y la identidad de sus responsables. Dicha entidad
        es la encargada del Registro Nacional de Bases de Datos, que es de
        acceso público y gratuito.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Asimismo, el usuario tiene derecho a acceder a la Información Personal
        que Nuestra Compañía recopila en relación con él y a solicitar la
        rectificación, actualización, eliminación y confidencialidad de la
        Información contenida en las bases de datos de la Compañía, todo ello de
        acuerdo con los artículos 14 y 16 de la Ley de Protección de Datos
        Personales Nº 25.326.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Finalmente, el usuario puede solicitar el retiro o bloqueo gratuito de
        su nombre y dirección de correo electrónico de archivos o bases de datos
        publicitarias (art. 27, párrafo 3 de la Ley Nº 25.326).
      </Typography>
      <Typography variant="body1" gutterBottom>
        Si en cualquier momento el usuario desea ejercer alguno de los derechos
        mencionados anteriormente u obtener más información sobre nuestro
        enfoque de las políticas de privacidad, no dude en ponerse en contacto
        con nosotros a través del canal de información proporcionado en el punto
        9 de este documento.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Si desea contactar a la Autoridad de Protección de Datos Personales, a
        continuación se detallan sus datos de contacto:
      </Typography>
      <Typography variant="body1" gutterBottom>
        Sarmiento 1118, 5º piso (C1041AAX)
        <br />
        Tel 4383-8510/12/13/15
        <br />
        www.jus.gov.ar/datospersonales
        <br />
        infodnpdp@jus.gov.ar
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        5. LINKS Y SITIOS DE TERCEROS.
      </Typography>
      <Typography variant="body1" gutterBottom>
        En los Servicios, el usuario encontrará links a páginas web de terceros.
        El Usuario entiende y acepta que, al hacer click en cualquiera de dichos
        links, cualquier información que provea luego de ello se encuentra
        sujeta a las políticas de privacidad de dichos terceros y no de
        PatitoChat. En consecuencia, PatitoChat no asume ninguna responsabilidad
        por el contenido, protección o seguridad de ningún sitio web de
        terceros.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        6. TÉRMINOS GENERALES.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Salvo cualquier disposición en contrario contenida en las presentes
        Políticas de Privacidad, PatitoChat podrá conservar o divulgar la
        información del usuario -incluso luego de que el usuario haya
        discontinuado o eliminado su cuenta o luego de finalizada la provisión
        de los Servicios- por todo el tiempo que ello resulte razonablemente
        necesario para cumplir con las presentes Políticas de Privacidad o con
        cualquier ley, regulación o requerimiento legal; para proteger o
        salvaguardar a cualquier persona; para abordar o combatir el fraude y/o
        cuestiones técnicas o de seguridad; o para proteger los derechos de
        propiedad de PatitoChat.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Si cualquier tribunal u otra autoridad competente resolviera acerca de
        la invalidez o inejecutabilidad de alguna disposición contenida en esta
        Política de Privacidad, las restantes disposiciones no se verán
        afectadas y conservarán plena vigencia y validez.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        7. MODIFICACIONES A LA POLÍTICA DE PRIVACIDAD.
      </Typography>
      <Typography variant="body1" gutterBottom>
        La versión más reciente y actualizada de la Política de Privacidad
        regulará el uso de la Información y se encontrará a disposición en esta
        página.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Nuestra Compañía se reserva el derecho de revisar o modificar estas
        Políticas de Privacidad en cualquier momento, haciendo públicas en el
        Sitio la Política de Privacidad modificada. Las Políticas de Privacidad
        modificadas entrarán en vigor a los 10 (diez) días de su publicación en
        el Sitio. PatitoChat podrá, asimismo -pero no se encontrará obligada a-
        notificar al usuario cualquier modificación de las Políticas de
        Privacidad mediante correo electrónico a la dirección de correo
        electrónico asociada a la cuenta del usuario.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Queda expresamente establecido que el uso de cualquiera de los
        Servicios, luego de efectuados los cambios en la Política de Privacidad,
        implica la aceptación de los mismos. Si Usted no estuviere de acuerdo
        con dichas modificaciones deberá abstenerse de utilizar los Servicios y
        podrá proceder al cierre de su cuenta de usuario conforme lo previsto en
        los TyC. Salvo que se indique lo contrario, nuestra política de
        privacidad actual será aplicable para toda la Información que tratamos
        vinculada al usuario.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        8. PRIVACIDAD PARA MENORES.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Proteger la privacidad de los menores es especialmente importante para
        Nosotros. Nuestros Servicios no están dirigidos a menores de edad, por
        tal motivo, PatitoChat no tiene intención de recopilar ni solicitar
        información personal a nadie menor de edad ni permitir deliberadamente
        que dichas personas se registren en los Servicios. Si tomamos
        conocimiento de que hemos recopilado, por error, información personal de
        un menor de edad sin verificación del consentimiento de los padres,
        tomaremos los pasos necesarios para eliminar esa información. Si crees
        que podríamos tener información de o sobre un menor de edad,
        contáctanos.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        9. TRANSFERENCIA INTERNACIONAL DE DATOS.
      </Typography>
      <Typography variant="body1" gutterBottom>
        La Información de los usuarios de Nuestra Compañía es almacenada en
        servidores de un tercero contratado por Nuestra Compañía que le presta
        el servicio de Cloud Computing, que para la legislación Argentina,
        constituye una prestación de servicios informatizados o prestación de
        servicios de tratamiento de información.
      </Typography>
      <Typography variant="body1" gutterBottom>
        En virtud de ello y conforme la normativa vigente, Nuestra Compañía: (a)
        realiza transferencia internacional de datos personales y (b) deja
        constancia que la transferencia de datos se efectúa a un país que cumple
        con los niveles de protección adecuados conforme lo establecido por el
        artículo 12 de la ley 25,326 y normas complementarias y que el proveedor
        del servicio de Cloud Computing cumple adecuadamente con los niveles de
        protección y seguridad pertinentes en materia de datos personales
        conforme la legislación vigente.
      </Typography>
      <Typography variant="h6" component="h2" gutterBottom>
        10. CONTACTO.
      </Typography>
      <Typography variant="body1" gutterBottom>
        Si Usted tiene alguna consulta o comentario respecto de la presente
        Política de Privacidad, le pedimos por favor tenga a bien contactarse
        escribiendo a hola@patitochat.com.
      </Typography>
    </Container>
  </Box>
);

export default PrivacyPolicy;
