import React from "react";
import { Box, Typography, Container, Link } from "@mui/material";

const Footer = () => (
  <Box
    sx={{
      backgroundColor: "#001f3f",
      color: "white",
      py: 2,
      textAlign: "center",
    }}
  >
    <Container maxWidth="xl">
      <Typography variant="body1">
        &copy; 2024 PatitoChat. All rights reserved.
      </Typography>
      <Link href="/terms" color="inherit" underline="hover" sx={{ mx: 2 }}>
        Términos y Condiciones
      </Link>
      <Link href="/privacy" color="inherit" underline="hover" sx={{ mx: 2 }}>
        Política de Privacidad
      </Link>
    </Container>
  </Box>
);

export default Footer;
