import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import Pricing from "./components/Pricing";
import Footer from "./components/Footer";
import Terms from "./components/Terms";
import PrivacyPolicy from "./components/PrivacyPolicy";
import { CssBaseline } from "@mui/material";
import "./App.css";

function App() {
  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>PatitoChat - Tu CRM de redes sociales</title>
          <meta
            name="description"
            content="PatitoChat es un CRM de redes sociales que te ayuda a gestionar tus interacciones de manera eficiente."
          />
          <meta
            name="keywords"
            content="PatitoChat, CRM, Social Media, Gestión de Redes Sociales"
          />
        </Helmet>
        <CssBaseline />
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <>
                <HeroSection />
                <Pricing />
              </>
            }
          />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
