import React from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Box,
} from "@mui/material";
import logo from "../assets/logopatito.png"; // Asegúrate de que la ruta del logo sea correcta

const Header = () => (
  <AppBar
    position="static"
    sx={{
      backgroundColor: "#ffffff",
      boxShadow: "none",
      borderBottom: "1px solid #e0e0e0",
    }}
  >
    <Container maxWidth="xl">
      <Toolbar disableGutters>
        <Box sx={{ display: "flex", alignItems: "center", flexGrow: 1 }}>
          <img
            src={logo}
            alt="PatitoChat Logo"
            style={{ width: 40, height: 40, marginRight: 8 }}
          />
          <Typography variant="h6" component="div" sx={{ color: "#000000" }}>
            PatitoChat
          </Typography>
        </Box>
        <Button
          color="inherit"
          href="#features"
          sx={{ color: "#000000", marginRight: 2 }}
        >
          Features
        </Button>
        <Button
          color="inherit"
          href="#pricing"
          sx={{ color: "#000000", marginRight: 2 }}
        >
          Pricing
        </Button>
        <Button color="inherit" href="#contact" sx={{ color: "#000000" }}>
          Contact
        </Button>
      </Toolbar>
    </Container>
  </AppBar>
);

export default Header;
