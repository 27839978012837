import React from "react";
import { Box, Typography, Grid, Paper, Button, Container } from "@mui/material";

const Pricing = () => (
  <Box
    id="pricing"
    sx={{
      backgroundColor: "#f4f4f4",
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Container maxWidth="xl">
      <Typography variant="h4" component="h2" gutterBottom textAlign="center">
        Pricing
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {["Basic", "Pro", "Enterprise"].map((plan, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper
              sx={{
                p: 3,
                textAlign: "center",
                backgroundColor: "#ffffff",
                boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
                borderRadius: "10px",
                transition: "transform 0.3s",
                "&:hover": {
                  transform: "translateY(-10px)",
                },
              }}
            >
              <Typography variant="h6" component="h3" gutterBottom>
                {plan}
              </Typography>
              <Typography variant="h4" component="p" gutterBottom>
                ${10 * (index + 1)}/month
              </Typography>
              <ul
                style={{
                  listStyleType: "none",
                  padding: 0,
                  marginBottom: "1rem",
                }}
              >
                <li>Feature 1</li>
                <li>Feature 2</li>
                <li>Feature 3</li>
                {index > 0 && <li>Feature 4</li>}
                {index > 1 && <li>Feature 5</li>}
              </ul>
              <Button
                variant="contained"
                color="primary"
                sx={{ backgroundColor: "#007BFF", color: "white" }}
              >
                Select
              </Button>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Container>
  </Box>
);

export default Pricing;
